(function($){

    'use strict';

    function JoinTeam(){

        var self = this,
            $formJob = null,
            formJobProcessing = null,
            options = {
                'example': null
            };


        function initFormFileHandler() {

            $formJob.on('change', 'input[type=file]', function (event) {
                var name = $(this).attr("name");

                // Check filesize
                if (event.target.files[0]) {


                    if (event.target.files[0].size > 5000000) {
                        $(this).val('').change();
                        $formJob.find(".notif-file-badsize").slideDown();
                        setTimeout(function(){
                            $formJob.find(".notif-file-badsize").slideUp();
                        }, 6000);
                    }

                }
            });
        }


        function validateForm($form){
            var valid = true;

            $form.find("[data-required]").each(function(index){
                $(this).removeClass("data-required");

                if($(this).val() === ""){
                    $(this).addClass("data-required");
                    valid = false;
                }
            });

            return valid;
        }

        function initForm(){
            $formJob = $("#formJob");
            $formJob.submit(function(e){
                e.preventDefault();

                $formJob.find(".txt-notification").hide();

                if( validateForm($formJob) ){


                    // Create a formdata object and add normal input the files
                    var data = new FormData();
                    var sArrData = $formJob.serializeArray();
                    $.each( sArrData , function( key , input ){
                        data.append( input.name , input.value );
                    });


                    // Append file to formdata
                    $formJob.find("input[type='file']").each(function(index, e){
                        var inputName = ($(e).attr("name"));

                        //Check if input contain file
                        if(typeof  e.files[0] === "object"){
                            data.append(inputName, e.files[0]);
                        }

                    });


                    $formJob.parent().block({
                        message: $("#spinner-ajax"),
                        overlayCSS: {
                            background: '#fff',
                            opacity: 0.6
                        }
                    });

                    $.ajax({
                        url: ajaxurl,
                        type: 'POST',
                        data: data,
                        cache: false,
                        dataType: 'json',
                        processData: false, // Don't process the files
                        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
                        success: function(data, textStatus, jqXHR)  {
                            $formJob.parent().unblock();
                            $formJob.find("[data-required], textarea").each(function(index){
                                $(this).val("");
                            });

                            if(data.status === "success"){
                                $formJob.find(".notif-succeed").slideDown();

                            }
                            // Display Error message
                            else if(data.status === "error"){

                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown){
                            console.log('ERRORS: ' + textStatus);
                        }
                    });


                }else{
                    $formJob.find(".notif-empty-field").slideDown();

                }

            });


            console.log("init form");
        }


        /**
         * Public constructor
         * @param  {Object} opts Options
         */
        self.initialize = function(opts) {
            //extends options
            for (var i in opts) {
                options[i] = opts[i];
            }


            initForm();

            initFormFileHandler();


        };


        self.initialize.apply(self, arguments);
        return self;

    }

    window.JoinTeam = JoinTeam;

}(jQuery));