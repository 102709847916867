(function($) {

  'use strict';

  function MeetTeam() {

    var self = this,
      $teamSlider = null,
      $addressCol = null,
      cityPoint = null,
      $cityName = null,
      $coordonneesBlock = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching
      // $teamSlider = $('.js-team-slider');

      $addressCol = $('.js-col-address');
      cityPoint = '.js-city-point';
      $cityName = $('.js-city');
      $coordonneesBlock = $('.js-coordonnees');


      // Team Slider
      // $teamSlider.slick({
      //   infinite: true,
      //   speed: 800,
      //   arrows: true
      // });

      $addressCol.hover(function() {
          var dataCity = $(this).attr('data-city');
          $(cityPoint +'[data-city="'+ dataCity +'"]').addClass('active');
      }, function() {
          $(cityPoint).removeClass('active');
      });


      // Mobile
      $cityName.click(function(){
          if(!$(this).next($coordonneesBlock).hasClass('active')) {
              $cityName.removeClass('active');
              $(this).addClass('active');

              $coordonneesBlock.removeClass('active');
              $(this).next($coordonneesBlock).addClass('active');
          } else {
            $(this).next($coordonneesBlock).removeClass('active');
            $(this).removeClass('active');
          }
      });


      // Form Contact
        $("#formContact").submit(function(e){
            e.preventDefault();
            var $form = $(this);
            $form.find(".txt-notif").hide();

            var error = 0;
            $form.find("[data-required]").each(function(){
                $(this).removeClass("data-required");
                if($(this).val() === ""){
                    $("#txtFormError").slideDown(300);
                    $(this).addClass("data-required");
                    error=1;
                }
            });

            //Si pas erreur,  on envoi
            if(error === 0){

                var sData = $(this).serialize();
                $.ajax({
                    method: "POST",
                    url: ajaxurl,
                    // dataType : "json",
                    data:  sData
                })
                .done(function( data ) {
                    if(data === "1") {
                        $("#txtFormSent").show();
                        $form.find("[data-required]").val("");
                    }
                });
            }
        });

      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.MeetTeam = MeetTeam;

}(jQuery));
