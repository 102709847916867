(function($) {

  'use strict';

  function Manufacturing() {

    var self = this,
      $teamSlider = null,
      $addressCol = null,
      cityPoint = null,
      $cityName = null,
      $coordonneesBlock = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    // function onResize() {

    // }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

        $('.carousel-manufacturing').slick({
          infinite: true
        });


      //*************
      // window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Manufacturing = Manufacturing;

}(jQuery));
