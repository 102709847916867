(function($) {

  'use strict';

  function Home() {

    var self = this,
      $teamSlider = null,
      $addressCol = null,
      cityPoint = null,
      $cityName = null,
      $coordonneesBlock = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    // function onResize() {

    // }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

        // For use within normal web clients 
        //var isiPad = navigator.userAgent.match(/iPad/i) != null;

        // For use within iPad developer UIWebView
        // Thanks to Andrew Hedges!
        var ua = navigator.userAgent;
        var isiPad = /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /iPhone OS 3_2_2/i.test(ua);

        if (isiPad === true){
          $('.big-banner').addClass('no-parallax');
          $('.block-frame-wood').addClass('no-parallax');
        }

      // setTimeout(function(){
      
        // carousel
        $('.js-home-thumbnail-carousel').slick({
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
      // }, 2000);


      // au scroll on cache le texte de gauche et on affiche celui de droite
      // $window = $(window);

      var windowHeight = $(window).height();
      var windowWidth = $(window).width();
      var scrollTop = $(window).scrollTop();
      var scrollBottom = $(window).scrollTop() + 400;

      var opacity;
      var visibility;

      $(window).scroll(function() {
        scrollTop = $(window).scrollTop();
        windowWidth = $(window).width();

        if(windowWidth > 767) {
          if ( scrollTop >= 0 && scrollTop <= scrollBottom ) {
            // opacity scroll
            opacity = (1 - scrollTop/350);
            // visibility scroll
            visibility = (0 + scrollTop/350);
            // si opacity inférieur à 0 ou supérieur à 1
            if ( opacity > -0.01  && opacity <= 1 ) {
              $(".js-fade-out").css({
                opacity: opacity
              });
            }

            // si visibility inférieur à 0 ou supérieur à 1
            if ( visibility > -0.01  && visibility <= 1.02 ) {
              $(".js-fade-in").css({
                opacity: visibility
              });
            }
          }
        } else {
          $(".js-fade-out").css({
            opacity: 1
          });

          $(".js-fade-in").css({
            opacity: 1
          });
        }

      });



      // window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Home = Home;

}(jQuery));
