(function($) {

  'use strict';

  function Header() {

    var self = this,
      $navTopBar = null,
      $navSection = null,
      $toggleMenu = null,
      $mainMenu = null,
      $submenu = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    function onResize() {

    }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching
      $navTopBar = $('.js-first-nav');
      $navSection = $('.js-nav-section');
      $toggleMenu = $('.js-toggle-menu');
      $mainMenu = $('.js-main-nav');
      $submenu = $('.sub-menu');


      // Menu mobile
      $toggleMenu.click(function() {
        if(!$mainMenu.hasClass('active')) {
          $mainMenu.addClass('active');
        } else {
          $mainMenu.removeClass('active');
        }
      });

      // Submenu Mobile
      if($toggleMenu.is(':visible')) {
        $('.menu-item-has-children > a').click(function(e) {
          e.preventDefault();

          var $thisSubmenu = $(this).parent().find($submenu);

          if(!$thisSubmenu.hasClass('active')) {
            $('.menu-item-has-children > a').removeClass('active');
            $(this).addClass('active');
            $submenu.removeClass('active');
            $thisSubmenu.addClass('active');
          } else {
            $(this).removeClass('active');
            $thisSubmenu.removeClass('active');
          }
        });

        // Max Height
        $mainMenu.css({'max-height' : ($(window).height()- $navSection.height())*0.95});
      }

      // Fixed Menu
      var topBarBottom = $navTopBar.outerHeight();

      $(window).on('load scroll', function() {
        if($(window).scrollTop() >= topBarBottom) {
            $navSection.addClass('fixed');
            $('body').css({'padding-top' : $navSection.outerHeight()});
        } else {
            $navSection.removeClass('fixed');
            $('body').css({'padding-top' : ''});
        }

        // FadeIn Logo Home
        if($(window).scrollTop() > 130) {
            $('.home .brand').addClass('visible');
        } else {
            $('.home .brand').removeClass('visible');
        }
      });


      //*************
      window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Header = Header;

}(jQuery));
