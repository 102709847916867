(function($) {

  'use strict';

  function Template2() {

    var self = this,
      $teamSlider = null,
      $addressCol = null,
      cityPoint = null,
      $cityName = null,
      $coordonneesBlock = null,
      options = {
        'example': null
      };

    /**
     * Generic resize
     */
    // function onResize() {

    // }


    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }


      // carousel
      $('.certifications-carousel').slick({
        infinite: true,
        dots: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });

      // window.cache.$window.on('load resize', onResize);
    };

    self.initialize.apply(self, arguments);
    return self;
    
  }

  /* Namespace declaration */
  window.Template2 = Template2;

}(jQuery));
